import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore, initializeFirestore, persistentLocalCache } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyCp4hRM6EjPjLD53UBYHC0UJPKE3X1DYjs',
  authDomain: 'ecocrafts-web.firebaseapp.com',
  projectId: 'ecocrafts-web',
  storageBucket: 'ecocrafts-web.appspot.com',
  messagingSenderId: '557679339931',
  appId: '1:557679339931:web:b3933727681e8432c3993e',
  measurementId: 'G-7HTBFF3GE7',
}
const ReCaptchaEnterpriseProviderKey = '6LcRxXApAAAAABx6ryOsrlDqirYfXJ7x2ysepdSd'
const app = initializeApp(firebaseConfig)

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(ReCaptchaEnterpriseProviderKey),
  isTokenAutoRefreshEnabled: true,
  // experimentalForceLongPolling: true,
  // useFetchStreams: false,
  // enablePersistence: true,
})
export const analytics = getAnalytics(app)
export const db = initializeFirestore(app, {
  experimentalForceLongPolling: true,
  localCache: persistentLocalCache({}),
})
export const static_assets_url = `https://storage.googleapis.com/${firebaseConfig.storageBucket}`
export const bucket = getStorage(app)
export default app
